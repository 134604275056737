import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { scrollToSection } from './utils/scroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface FAQ {
    question: string;
    answer: string;
}
  
const FAQs: FAQ[] = [
    { question: "スマホがないお客様は利用できませんか？", answer: "スタッフ様が代理で注文したり、管理画面からも代理注文して対応します。" },
    { question: "月額料金以外に追加費用は発生しますか？", answer: "基本的に初期費用、月額費用以外で発生するコストはございません。ただし、サーバー、ドメインを独自のものにしたいという場合はお見積りが必要です。" },
    { question: "最低利用期間はありますか？", answer: "最低利用期間は6ヶ月とさせて頂きます。毎月20日に次月ご請求を行いますので、それまでに営業担当者へお知らせ下さい。" },
    {
        question: "商品・メニューの更新はどのように行いますか？",
        answer: "管理画面からメニューを登録・更新することができ、変更は即座にアプリに反映されます。"
    },
    {
        question: "いまある機能の改善をして欲しい",
        answer: "定期的にアップデートを行っており、新機能の追加やバグの修正を通じてサービス品質の向上に努めています。お客様からのフィードバックや要望があれば、営業担当までお気軽にご連絡ください。"
    }
];

interface TestimonialData {
    name: string;
    comment: string;
    image: string;
    role: string;
}
  
const testimonials = [
    {
        name: "T",
        role: "カフェオーナー",
        comment: "QReoの導入で注文ミスが激減し、スタッフの負担も大幅に軽減されました。お客様からも好評です！",
        image: "/images/testimonials/icn_owner.svg"
    },
    {
        name: "S",
        role: "居酒屋経営者",
        comment: "繁忙期の注文対応がスムーズになり、売上が20%も向上しました。本当に助かっています。",
        image: "/images/testimonials/icn_woman.svg"
    },
    {
        name: "I",
        role: "レストラン店長",
        comment: "外国人のお客様にも簡単に注文いただけるようになり、インバウンド需要の取り込みに成功しました。",
        image: "/images/testimonials/icn_cook.svg"
    }
];
const Testimonial: React.FC<TestimonialData> = ({ name, role, comment, image }) => (
    <div className="testimonial">
      <img src={image} alt={`${name}様`} className="testimonial-image" />
      <div className="testimonial-content">
        <p className="testimonial-comment">"{comment}"</p>
        <p className="testimonial-author">{role}</p>
      </div>
    </div>
  );
  

const Qreo: React.FC = () => {
    const heroRef = useRef<HTMLDivElement>(null);
    const featuresRef = useRef<HTMLDivElement>(null);
    const howtoRef = useRef<HTMLOListElement>(null);
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);


    const toggle = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const splideOptions = {
        type: 'loop',
        rewind: true,
        autoplay: true,
        interval: 5000,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        gap: '1rem',
    };

    const apiUrl = 'https://qr-mail.alternative.ne.jp';

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        
        if (!name.trim()) {
            newErrors.name = 'お名前は必須です。';
            console.log('名前が入力されていません');
        }
        
        if (!email.trim()) {
            newErrors.email = 'メールアドレスは必須です。';
            console.log('メールアドレスが入力されていません');
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = '有効なメールアドレスを入力してください。';
            console.log('無効なメールアドレス形式です');
        }
        
        if (!message.trim()) {
            newErrors.message = 'メッセージは必須です。';
            console.log('メッセージが入力されていません');
        }
        
        // プライバシーポリシーのチェックボックスの状態を確認
        const privacyCheckbox = document.getElementById('privacy-policy') as HTMLInputElement;
        if (!privacyCheckbox || !privacyCheckbox.checked) {
            newErrors.privacy = 'プライバシーポリシーに同意する必要があります。';
            console.log('プライバシーポリシーに同意されていません');
        }
         
        setErrors(newErrors);

        const isValid = Object.keys(newErrors).length === 0;
        console.log('フォームのバリデーション結果:', isValid);
        return isValid;
      };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isSubmitting || !validateForm()) return; 
        setIsSubmitting(true);

        console.log('フォーム送信開始');

        try {
            const response = await fetch(`${apiUrl}/send-email/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ name, email, message }),
            });
            console.log('Response status:', response.status);
            console.log('Response headers:', response.headers);
            const responseData = await response.text();
            console.log('Response data:', responseData);

            if (response.ok) {
                alert('メッセージが送信されました。');
                // フォームをリセット
                setName('');
                setEmail('');
                setMessage('');
            } else {
                alert('メッセージの送信に失敗しました。');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('エラーが発生しました。');
        } finally {
            setIsSubmitting(false);
            console.log('Form submitted');
        }
    };
  

    useEffect(() => {
        const squaresContainer = document.createElement('div');
        squaresContainer.classList.add('squares-container');
        if(!document.querySelector('.squares-container')) {
            if (heroRef.current) {
                heroRef.current.appendChild(squaresContainer);
                const heroBounds = heroRef.current.getBoundingClientRect();
                heroRef.current.appendChild(squaresContainer);
                squaresContainer.style.position = 'absolute';
                squaresContainer.style.width = `${heroBounds.width}px`;
                squaresContainer.style.height = `${heroBounds.height}px`;
                squaresContainer.style.top = '0';
                squaresContainer.style.left = '0';

                for (let i = 0; i < 10; i++) {
                    const square = document.createElement('div');
                    square.classList.add('animatedSquare');
                
                    const randomXStart = Math.random() * heroBounds.width;
                    const randomYStart = Math.random() * heroBounds.height;
                    
                    const moveDistance = 100;
                    const randomXEnd = randomXStart + (Math.random() * moveDistance - moveDistance / 2);
                    const randomYEnd = randomYStart + (Math.random() * moveDistance - moveDistance / 2);

                    const duration = 5 + Math.random() * 5; // 5秒から10秒のランダムな期間

                    square.style.left = `${randomXStart}px`;
                    square.style.top = `${randomYStart}px`;
                    square.style.setProperty('--randXStart', `0px`);
                    square.style.setProperty('--randYStart', `0px`);
                    square.style.setProperty('--randXEnd', `${randomXEnd - randomXStart}px`);
                    square.style.setProperty('--randYEnd', `${randomYEnd - randomYStart}px`);
                    square.style.animation = `moveRandomly ${duration}s ease-in-out infinite`;

                    squaresContainer.appendChild(square); 
                }
            }
        }
    }, []);
   
    useEffect(() => {
        const timer = setTimeout(() => {
            const animateElements = gsap.utils.toArray('.animate');
        
            animateElements.forEach((element: any) => {
              gsap.from(element, {
                opacity: 0,
                y: 50,
                duration: 1,
                scrollTrigger: {
                  trigger: element,
                  start: 'top bottom-=100',
                  toggleActions: 'play none none reverse',
                  markers: false
                }
              });
            });
          }, 100);
        
          // クリーンアップ関数
          return () => {
            clearTimeout(timer);
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
          };
    }, []);

    // Features section animation
    useEffect(() => {
        if (featuresRef.current) {
          const featureElements = [
            featuresRef.current.querySelector('.features01 li:first-child'),
            featuresRef.current.querySelector('.features01 li:last-child'),
            featuresRef.current.querySelector('.features03 li:first-child'),
            featuresRef.current.querySelector('.features03 li:last-child'),
            featuresRef.current.querySelector('.features02 img')
          ];
    
          featureElements.forEach((element, index) => {
            if (element) {
              gsap.set(element, { opacity: 0, y: 50 }); // 初期状態を設定
    
              gsap.to(element, {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                  trigger: element,
                  start: 'top bottom-=100',
                  end: 'bottom top+=100',
                  toggleActions: 'play none none reverse',
                  // markers: true, // デバッグ用
                },
                delay: index * 0.3 // 各要素に少しずつ遅延を加える
              });
            }
          });
        }
    
        return () => {
          ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
      }, []);

      // How to use section animation
      useEffect(() => {
        if (howtoRef.current) {
          const howtoItems = howtoRef.current.querySelectorAll('.howto-item');
    
          howtoItems.forEach((item, index) => {
            gsap.set(item, { opacity: 0, y: 50 }); 
            gsap.to(item, {
              opacity: 1,
              y: 0,
              duration: 0.5,
              scrollTrigger: {
                trigger: item,
                start: 'top bottom-=50', // 要素の上端がビューポートの下から50px上に来たら開始
                end: 'bottom top+=50',   // 要素の下端がビューポートの上から50px下に来たら終了
                toggleActions: 'play none none reverse',
              },
              delay: index * 0.3,
            });
          });
        }
    
        return () => {
          ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
      }, []);
    

    return (
        <div className="content">
        <section id="hero" className="hero my-16" ref={heroRef}>
            <div className="hero-copy">
                <span className='main-copy'>Enjoy! </span>
                <span className='main-copy-2'>Order with QR</span>
                <p className="sub-copy">QRコードオーダーシステム「QReo」で<br />
            顧客満足度と業務効率をアップさせます！</p>
            </div>
            <figure className="hero-img animate">
                <img src="/images/img_circle_hero.webp" alt="QReo利用イメージ" />
            </figure>
            <button className='btn-submit mb-4' onClick={() => scrollToSection('form')}>お問い合わせ</button>
        </section>

        <section id="features" className="features my-16" ref={featuresRef}>
            <div className="container">
                <div className='section-title animate'>
                    <h2 className="ttl-ja">サービスの特徴</h2>
                    <p className='ttl-sub'>Features</p>
                </div>
                <div className="features-list">
                    <ul className="features01">
                        <li className="my-3">
                            <img src="/images/icn_order.svg" alt="Easy Order" className='feature-icn' />
                            <h3 className='mb-2'>簡単注文</h3>
                            <p className='mb-2'>スマートオーダーの新時代! 面倒な会員登録、アプリ導入は不要です</p>
                            <p>
                            QRコードを読むだけでメニュー表示。直感的な操作で簡単注文が可能になりました。
                            </p>
                            </li>
                        <li className="my-3">
                            <img src="/images/icn_qr.svg" alt="Low cost" className='feature-icn' />
                            <h3 className='mb-2'>端末の購入不要</h3>
                            <p className='mb-2'>高価な専用端末の購入は不要です。初期投資とランニングコストを大幅カット。
                            </p>
                            <p>スムーズな導入で即効果実感できます。コスト削減でスマートな経営を実現します</p>
                        </li>
                    </ul> 
                    <figure className="features02">
                        <img src="/images/img_qreo_hero.webp" alt="QRコード" className="w-1/2" />
                    </figure>
                    <ul className="features03">
                        <li className="my-3">
                            <img src="/images/icn_time.svg" alt="Priceless" className='feature-icn' />
                            <h3 className='mb-2'>業務効率改善</h3>
                            <p className='mb-2'>リアルタイムでオーダー状況を把握でき、テーブルごとの注文履歴を一目で確認できます。</p>
                            <p>
                                スタッフの動線を最適化し、
データ分析による業務改善の実現と効率的な店舗運営をサポートします。
                                </p>
                        </li>
                        <li className="my-3">
                            <img src="/images/icn_satisfy.svg" alt="Order Icon" className='feature-icn' />
                            <h3 className='mb-2'>顧客満足度の向上</h3>
                            <p>プライバシーに配慮した注文方法を実現し、注文のタイミングを顧客が自由に選択できるため、店内で大声で叫んだり、順番に注文を取りに来るまでお待たせしません。</p> 
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section id="testimonials" className="testimonials my-16">
            <div className="container">
                <div className='section-title animate'>
                    <h2 className="ttl-ja">導入事例</h2>
                    <p className='ttl-sub'>Testimonials</p>
                </div>
                <Splide options={splideOptions}>
                    {testimonials.map((testimonial, index) => (
                    <SplideSlide key={index}>
                        <Testimonial {...testimonial} />
                    </SplideSlide>
                    ))}
                </Splide>
            </div>
        </section>

        <section id="howto" className="sec-howto my-16">
            <div className="container">
                <div className='section-title animate'>
                    <h2 className="ttl-ja">
                        <img src="/images/logo_qreo_pink.svg" alt="QReo" width={123} height={40} />の使い方</h2>
                    <p className='ttl-sub'>How To Use</p>
                </div>
                <ol className="howto pl-5 mt-6" ref={howtoRef}>
                    <li className='howto-item'>
                        <img src="/images/img_howto_01.webp" alt="QRコード設置" className="howto-img"/>
                        <h3 data-number="1">QRコードを設置</h3>
                        <p>あらかじめ各テーブルにQRコードを設置しておきます。</p>
                    </li>
                    <li className='howto-item'>
                        <img src="/images/img_howto_02.webp" alt="顧客がスキャン" className="howto-img"/>
                        <h3 data-number="2">顧客がスキャン</h3>
                        <p>スマホでQRをスキャンし、メニューへアクセス</p>
                    </li>
                    <li className='howto-item'>
                        <img src="/images/img_howto_03.webp" alt="メニューを選んで注文" className="howto-img"/>
                        <h3 data-number="3">メニューを選んで注文</h3>
                        <p>オーダーを選んで注文を送信します。</p>
                    </li>
                    <li className='howto-item'>
                        <img src="/images/img_howto_04.webp" alt="管理画面で確認" className="howto-img"/>
                        <h3 data-number="4">管理画面で確認</h3>
                        <p>厨房にいても管理画面で確認できるから、スムーズに調理が開始できます。</p>
                    </li>
                    <li className='howto-item'>
                        <img src="/images/img_howto_05.webp" alt="オーダー提供" className="howto-img"/>
                        <h3 data-number="5">オーダー提供</h3>
                        <p>注文テーブルへお届けして完了です。</p>
                    </li>
                </ol>
            </div>
        </section>

        <section id="price" className="pricing my-16">
            <div className="container">
                <div className='section-title animate'>
                    <h2 className="ttl-ja">利用料金</h2>
                    <p className='ttl-sub'>Price</p>
                </div>
                <div className='pricing-list'>
                    <p className='price-month en'>￥16,500<span className='price-meta'>(税込)/ 1ヶ月</span><sup className='notion'>*1</sup></p>
                    <p className='price-initial'><span className="price-meta">初期費用</span>￥55,000<span>（税込）</span><sup className='notion'>*2</sup></p>
                </div>
                <div className='note'>
                    <p className='note-txt'>*1 月額料金は、店舗の席数によらず、1店舗あたりの料金です。</p>
                    <p className='note-txt'>*2 初期費用は、システム設定、店舗導入のサポートなど利用可能まですべて含んだ費用です。</p>
                </div>
            </div>
        </section>

        <section className="faq my-16">
            <div className="container">
            <div className='section-title animate'>
                    <h2 className="ttl-ja">よくあるご質問</h2>
                    <p className='ttl-sub'>FAQ</p>
                </div>
                <dl className="faq-list">
                {FAQs.map((faq, index) => (
                    <React.Fragment key={index}>
                    <dt onClick={() => toggle(index)} className={`faq-question ${openIndex === index ? 'active' : ''}`}>
                        {faq.question}
                    </dt>
                    <dd className={`faq-answer ${openIndex === index ? 'open' : ''}`} style={{ maxHeight: openIndex === index ? '100px' : '0' }}>
                        {faq.answer}
                    </dd>
                    </React.Fragment>
                ))}
                </dl>
            </div>
        </section>

        <section id="form" className="contact-form">
            <div className="section-title animate">
                <h2 className="ttl-ja">お問い合わせ</h2>
                <p className='ttl-sub'>Contact</p>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                    <label htmlFor="name">お名前</label>
                    <input 
                        type="text" 
                        id="name" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="お名前を入力してください" 
                        required 
                        />
                    {errors.name && <p className="error-message">{errors.name}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="email">メールアドレス</label>
                    <input 
                        type="email" 
                        id="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="メールアドレスを入力してください" 
                        required 
                        />
                </div>
                <div className="form-group">
                    <label htmlFor="message">メッセージ</label>
                    <textarea 
                        id="message" 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={6}
                        placeholder="ご質問・ご要望などをお聞かせ下さい。"
                        required
                        ></textarea>
                </div>
                <div className="form-group">
                    
                    <div className='privacy-policy-check mb-4'>
                        <input type="checkbox" id="privacy-policy" name="privacy-policy" required />
                        <label htmlFor="privacy-policy"><a href="/privacy-policy" className='link-uline'>プライバシーポリシー</a>に同意する</label>
                    </div>
                </div>
                <button type="submit" className='btn-submit mb-4' disabled={isSubmitting}>
                    {isSubmitting ? '送信中...' : '送信する'}
                </button>
            </form>
        </section>

        <footer className="site-footer">
            <div className='footer-branding animate'>
                <h1><img src="/images/logo_qreo_white.svg" alt="QRコードテーブルオーダーシステム「QReo」" width={126} height={40} /></h1>
                <small>QRコード注文システム</small>
            </div>
            <div className='footer-nav mb-4'>
                    <a href="#" className="link-footer">プライバシーポリシー </a>
                    <a href="#" className="link-footer">特商法取引に基づく表記 </a>
                    <a href="#" className='link-footer'>利用規約</a>
                </div>
            <p className='en'>© 2023 QReo. All rights reserved.</p>
        </footer>

        <div className="overlay"></div>
    </div>
    );
}

export default Qreo;
