import React, { useState, useEffect, useRef } from 'react';
import { scrollToSection } from '../utils/scroll';
import gsap from 'gsap';

const Navigation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 880);
  const [logoSize, setLogoSize] = useState({ width: 252, height: 80 });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 880;
      setIsMobile(isMobileView);
      setLogoSize(isMobileView 
        ? { width: Math.round(252 * 2/3), height: Math.round(80 * 2/3) }
        : { width: 252, height: 80 }
      );
      if (!isMobileView && !isInitialLoad) {
        gsap.to('.nav-global', { right: '0', duration: 0 });
        if (menuRef.current) {
          gsap.to(menuRef.current.querySelectorAll('li'), { opacity: 1, x: 0, duration: 0 });
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isInitialLoad]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMobile) {
      if (menuRef.current) {
        const menuItems = menuRef.current.querySelectorAll('li');
        
        if (isMenuOpen) {
          gsap.to('.nav-global', {
            right: '0%',
            duration: 0.3,
            ease: 'power2.out',
            onComplete: () => {
              gsap.fromTo(menuItems, 
                { opacity: 0, x: -50 },
                { 
                  opacity: 1, 
                  x: 0, 
                  duration: 0.3, 
                  stagger: 0.1, 
                  ease: 'power2.out',
                  delay: 0.5
                }
              );
            }
          });
        } else {
          gsap.to(menuItems, {
            opacity: 0,
            x: -50,
            duration: 0.2,
            stagger: 0.05,
            ease: 'power2.in',
            onComplete: () => {
              gsap.to('.nav-global', {
                right: '-100%',
                duration: 0.3,
                ease: 'power2.in'
              });
            }
          });
        }
      }
    }
  }, [isMenuOpen, isMobile]);

  useEffect(() => {
    if (isInitialLoad) {
      if (!isMobile) {
        gsap.from('.nav-global', { right: '-100%', duration: 0.5, ease: 'power2.out' });
        if (menuRef.current) {
          gsap.from(menuRef.current.querySelectorAll('li'), {
            opacity: 1,
            x: 0,
            duration: 0.5,
            stagger: 0.1,
            ease: 'power2.out',
            delay: 0.5
          });
        }
      }
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, isMobile]);

  return (
    <div className='app-nav'>
      <header className='branding'>
        <h1><img src="/images/logo_qreo_white.svg" alt="QRコードテーブルオーダーシステム「QReo」" width={logoSize.width} height={logoSize.height} /></h1>
        <p>{`QRコードでかんたん注文するなら「QReo」(クレオ)`}</p>
      </header>
      <nav className={`nav-global ${isMenuOpen || !isMobile ? 'open' : ''}`}>
        {isMobile && (
          <figure><img src="/images/logo_qreo_pink.svg" alt="QReoロゴ" width={126} height={40} /></figure>
        )}
        <ul ref={menuRef}>
          <li><a onClick={() => scrollToSection('hero')}>ホーム</a></li>
          <li><a onClick={() => scrollToSection('features')}>特徴</a></li>
          <li><a onClick={() => scrollToSection('howto')}>使い方</a></li>
          <li><a onClick={() => scrollToSection('price')}>料金</a></li>
          <li><a onClick={() => scrollToSection('faq')}>よくある質問</a></li>
        </ul>
      </nav>
      {isMobile && (
        <button className='btn-menu nav-menu' onClick={toggleMenu}>
          {isMenuOpen ? '×' : '≡'}
        </button>
      )}
      {!isMobile && (
        <button className='btn-apply' onClick={() => scrollToSection('form')}>
          今すぐ！申し込む
        </button>
      )}
    </div>
  );
};

export default Navigation;