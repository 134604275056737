import './App.css';
import Qreo from './Qreo';

function App() {
  return (
    <div className="App">
      <Qreo />
    </div>
  );
}

export default App;
